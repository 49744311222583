@import "../ComponentStyles/base";

.contactForm-wrapper {
    width: 100vw;
    height: 100vh;
    background-color: #FF003D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;

    .back-button {
        height: 100%;
        width: 30%;
        display: flex;
        justify-content: center;
        align-self: flex-end;
        align-items: center;
        margin-bottom: 0;
        margin-top: 10%;
        margin-right: 5%;

        a {
            text-decoration: none;
            color: $purple;
            font-weight: bolder;
            font-size: 2.5em;

            &:hover {
                color: white;
            }
        }
    }

    .sidebar {
        width: 90%;
        margin: 10px 0px 45px 0px;
        background-color: rgba(255, 255, 255, 0.5);
        border: 3px solid rgba(255, 255, 255, 0.2);
        border-radius: 5%;
        padding: 10px;
    }
    .notification {
        height: 100vh;
        width: 100vw;
        background-color: rgba(255, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: absolute;
        z-index: 5;

        .answer {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            padding: 10%;

            h1 {
                font-size: 4em;
                font-weight: bolder;
                color: white;
                margin: 0;
                align-self: flex-end;
                justify-self: flex-start;
                margin-right: 5%;

                &:hover {
                    color: rgba(0, 0, 0, 0.8);
                }
            }
            h3 {
                font-weight: bolder;
                font-size: 3em;
                color: white;
                margin: 0;
            }
        }
    }
}

@media (min-width: 768px) {
    .contactForm-wrapper {
        .sidebar {
            width: 60%;
        }
    }
}

@media(min-width: 1024px){
    .contactForm-wrapper{
        .sidebar{
            margin-bottom: 15%;
        }
    }
}

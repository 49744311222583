@import "../ComponentStyles/base";

.landing-wrapper {
    background: #fdfbfb; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #fdfbfb,
        #ebedee
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #fdfbfb,
        #ebedee
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 20px 20px 0 20px;
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 3em;
        color: $purple
    }
    h3{
        font-size: 1.5em;
    }
    #a {
        p {
            font-size: 1em;
            line-height: 1.5em;
            margin-left: 5%;
        }
    }

    #b {
        h3 {
            margin-left: 5%;
            margin-top: 8px;
            margin-bottom: 15px;
        }
        h3:first-of-type {
            font-family: "Montserrat", sans-serif !important;
            font-size: 3em;
            font-weight: 900;
            letter-spacing: 0.2em;
            margin: 0;
        }

        .list-container {
            margin-left: 10%;
            margin-top: 15px;

            ul {
                padding-left: 20px;

                li {
                    list-style-type: circle;
                    margin-top: 5px;
                }
            }
        }
    }

    #d {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-size: 1.5em;
            margin-bottom: 30px;
        }
        .contact-container {
            width: 30vw;
            height: 50px;
            border-radius: 1.5%;
            background-color: rgba(0, 0, 0, 0.05);
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-transition: 0.18s ease-out;
            -moz-transition: 0.18s ease-out;
            -o-transition: 0.18s ease-out;
            transition: 0.18s ease-out;
            box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.2);
            margin-bottom: 30px;

            &:hover {
                box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.5);
            }
        }
    }
    .text-card {
        height: 100%;
        width: 100%;
        margin: 0 0 20px 0px;
        display: flex;
        flex-direction: column;
        // padding: 0 15px 0 10px;

        h1 {
            margin: 0 0 10px 0;
            text-align: center;
        }
        h3 {
            text-align: center;
        }
        p {
            font-size: 8px;
        }

        .picture {
            align-self: center;
            background-image: url(./../assets/bitMe.JPG);
            background-repeat: no-repeat;
            background-size: contain;
            height: 125px;
            width: 125px;
            margin-bottom: 20px;
            border-radius: 35%;
        }

        .icon-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-top: 10px;

            .html {
                @include icon-set;
                background-image: url(./../assets/html-5.svg);
            }
            .css {
                @include icon-set;
                background-image: url(./../assets/css-3.svg);
            }
            .sass {
                @include icon-set;
                background-image: url(./../assets/512px-Sass_Logo_Color.svg.png);
            }
            .styled-components{
                @include icon-set;
                background-image: url(./../assets/styled-components-logo.png);
            }
            .javascript {
                @include icon-set;
                background-image: url(./../assets/Javascript_badge.svg);
            }
            .react {
                @include icon-set;
                background-image: url(./../assets/react.svg);
            }
            .nodejs {
                @include icon-set;
                background-image: url(./../assets/nodejs.svg);
            }
            .express {
                @include icon-set;
                background-image: url(./../assets/express.svg);
            }
            .mongoose{
                @include icon-set;
                background-image: url(./../assets/mongooseBadge.png);
            }
            .graphql{
                @include icon-set;
                background-image: url(./../assets/graphql.png)
            }
            .mongodb {
                @include icon-set;
                background-image: url(./../assets/mongodb.svg);
            }
            .jest{
                @include icon-set;
                background-image: url(./../assets/jestlogo.png)
            }
            .api {
                @include icon-set;
                background-image: url(./../assets/api-logo.png);
            }
            .bootstrap {
                @include icon-set;
                background-image: url(./../assets/bootstrap-png-bootstrap-1024.png);
            }
            .photoshop {
                @include icon-set;
                background-image: url(./../assets/photoshop-logo-png-open-2000.png);
            }
            .illustrator {
                @include icon-set;
                background-image: url(./../assets/512px-Adobe_Illustrator_CC_icon.svg.png);
            }
            .indesign {
                @include icon-set;
                background-image: url(./../assets/Adobe_InDesign_CC_icon.svg.png);
            }
            .git {
                @include icon-set;
                background-image: url(./../assets/Git-Icon-1788C.png)
            }
            .npm {
                @include icon-set;
                background-image: url(./../assets/Npm-logo.svg.png)
            }
            .wordpress {
                @include icon-set;
                background-image: url(./../assets/wp.png);
            }
        }
        .projects-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .zen {
                @include project-set;

                .zenpic {
                    background-image: url(./../assets/zen-pic.jpg);
                    @include projectPic;
                    // @include linearGradient($random-color, light);
                }
            }
            .business {
                @include project-set;

                .businesspic {
                    background-image: url(./../assets/Business-time.png);
                    @include projectPic;
                }
            }
            .mario {
                @include project-set;

                .mariopic {
                    @include projectPic;
                    @include linearGradient($random-color, dark);
                }
            }
            .ibm {
                @include project-set;

                .ibmpic {
                    background-image: url(./../assets/IBM-pic.jpg);
                    @include projectPic;
                    // @include linearGradient($random-color, darkest);
                }
            }
            .bounty {
                @include project-set;

                .bountypic {
                    @include projectPic;
                    background-image: url(./../assets/star-wars.png)
                }
            }
            .photo {
                @include project-set;

                .photopic {
                    @include projectPic;
                    @include linearGradient($random-color, lightest);
                }
            }
            .xillow {
                @include project-set;

                .xillowpic {
                    @include projectPic;
                    background-image: url(./../assets/xillow.png);
                    object-fit: cover;
                    overflow: hidden;
                }
            }
            .cfo-annual{
                @include project-set;
                
                .cfo-annualpic{
                    @include projectPic;
                    background-image: url(./../assets/cfo-report.png);
                }
            }
            .cfo-event{
                @include project-set;

                .cfo-eventpic{
                    @include projectPic;
                    background-image: url(./../assets/CFO_workplace-charging_p2.jpg);
                }
            }

            .cfo-design{
                @include project-set;

                .cfo-designpic{
                    @include projectPic;
                    background-image: url(./../assets/CFO-Info.jpg);
                }
            }

            .links {
                display: flex;
                flex-direction: row;
                margin: 3px 0px 0px 0px;
                li {
                    margin-left: 25px;

                    &:hover{
                        color: $red;
                    }
                }
            }

            .tech-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                margin-top: 5px;
                margin-left: 10px;
                margin-right: 10px;
                margin-bottom: 5px;
                padding-left: 7%;

                h4 {
                    margin-right: 5px;
                    margin-bottom: 0;
                }
                .tech {
                    font-size: 0.8em;
                    margin-bottom: 0;
                    margin-top: 3px;
                }
            }
            p {
                padding-right: 25px;
                padding-left: 25px;
                font-size: 0.8em;
                margin-bottom: 5px;
            }
        }

        .resume {
            margin-top: 10px;
            margin-bottom: 10px;
            height: 100px;
            width: 100px;
            background-image: url(./../assets/document-icon.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            &:hover {
                box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.5);
            }
        }
    }
}

@media (min-width: 768px) {
    .landing-wrapper {
        #a {
            p{
                line-height: 1.8em;
                font-size: 1.3em;
            }
            h1{
                font-size: 4em;
            }
            h3{
                font-size: 2em;
            }
        }
        #d {
            .contact-container {
                font-size: 1.25em;
            }
        }
        .text-card {
            .projects-container {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: space-evenly;
            }
        }
    }
}

@media (min-width: 1024px) {
    .landing-wrapper {
        #a{
            p{
                padding-left: 60px;
                padding-right: 60px;
            }
        }
        #d {
            .contact-container {
                font-size: 1.5em;
            }
        }
        .text-card {
            h3 {
                font-size: 2em;
            }

            .picture {
                height: 200px;
                width: 200px;
            }

            .list-container {
                font-size: 1.5em;
            }

            .projects-container {
                .links {
                    margin-top: 1px;
                    margin-bottom: 3px;
                    font-size: 1.2em;
                }
                .tech-container {
                    h4 {
                        font-size: 1.2em;
                    }
                    .tech {
                        font-size: 1em;
                    }
                }
            }
        }
    }
}


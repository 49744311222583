@import "../ComponentStyles/base";

.splash-container::-webkit-scrollbar{
    height: 0;
    width: 0;
}
.splash-container {
    @include splash-dim;
    position: relative;
    width: 100vw;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */

    .hello-container {
        height: 25%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        z-index: 500;
        position: absolute;
        padding-left: 5%;
        padding-right: 5%;

        .hello {
            margin-top: 25%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            height: 50vh;
            width: 82vw;
            z-index: 500;

            .letters {
                font-family: "Montserrat", sans-serif !important;
                font-weight: 900;
                font-size: 9em;
                color: white;
                margin-top: -40px;
                opacity: 0;
                z-index: 500;
                animation: 1.5s cubic-bezier(0.25, .1, .42, 1.25) both
                    roll-fade;
                border-radius: 4px;
                float: left;
                // animation-delay: 3s;
                // animation: fadein 2s forwards;
                // -webkit-animation-delay: 3s;
                // -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
                // -moz-animation-delay: 3s;
                // -moz-animation: fadein 2s forwards;
            }

            .letters:last-child {
                color: #ff003d;
            }

            // Loop
            @for $i from 1 through 6 {
                .letters {
                    &:nth-child(#{$i}) {
                        // Delay the animation. Delay increases as items loop.
                        animation-delay: $i * (0.35s);
                    }
                }
            }

            @keyframes roll-fade {
                0% {
                    opacity: 0;
                    transform: translateX(50%) scale(0) rotateZ(-60deg);
                }

                100% {
                    opacity: 1;
                    transform: translateX(0) scale(1) rotateZ(0deg);
                }
            }

            // @keyframes fadein {
            //     from {
            //         opacity: 0;
            //     }
            //     to {
            //         opacity: 1;
            //     }
            // }

            // /* Firefox < 16 */
            // @-moz-keyframes fadein {
            //     from {
            //         opacity: 0;
            //     }
            //     to {
            //         opacity: 1;
            //     }
            // }

            // /* Safari, Chrome and Opera > 12.1 */
            // @-webkit-keyframes fadein {
            //     from {
            //         opacity: 0;
            //     }
            //     to {
            //         opacity: 1;
            //     }
            // }
        }
    }
    #arrow {
        background-image: url(./../assets/down-arrow.svg);
        background-repeat: no-repeat;
        background-size: contain;
        height: 50px;
        width: 50px;
        z-index: 200;
        transition: 2.5s;
        animation: bounce 1s infinite alternate, fadein 6s forwards;
        -webkit-animation: bounce 1s infinite alternate, fadein 6s forwards;
    }

    @keyframes bounce {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-10px);
        }
    }
    @-webkit-keyframes bounce {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-10px);
        }
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    #intro-container {
        color: white;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        height: 100%;
        width: 100vw;

        #intro {
            text-align: center;
            display: flex;
            position: absolute;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-top: -40%;
            width: 90%;
            word-wrap: break-word;
            z-index: 130;
            color: white;
            width: 90%;

            h1 {
                font-family: "Roboto Condensed", sans-serif !important;
                font-weight: 900;
                font-size: 2.6rem;
                display: inline-block;
                width: 100%;
                margin-bottom: 5px;
                padding-bottom: 5px;
                border-bottom: 2px solid rgba(255, 255, 255, 0.75);
                color: white;
            }

            h3 {
                font-family: "Roboto", sans-serif !important;
                font-size: 2em;
                font-weight: 700;
                margin-top: 10px;
                margin-bottom: 0px;
                z-index: 120;
                display: inline-block;
                color: white;
            }

            h5 {
                font-family: "Roboto", sans-serif !important;
                font-weight: light;
                font-size: 1.3em;
                margin-top: 5px;
                line-height: 1.8em;
                display: block;
                color: white;
            }
        }

        #programming {
            background-image: url(./../assets/programming-vector2_animated.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 50vh;
            width: 100%;
            margin-top: 50vh;
            margin-bottom: 1%;
            padding: 0;
            z-index: 100;
            align-self: center;
            justify-self: center;
            display: block;
        }
    }
}

@media (min-width: 375px) {
    .splash-container {
        .hello-container{
            padding-left: 10%;
            padding-right: 10%;
        }
        #intro-container {
            #programming {
                margin-top: 40vh;
                height: 100%;
            }
        }
    }
}

@media (min-width: 425px) {
    .splash-container {
        .hello-container{
            padding-left: 15%;
            padding-right: 15%;
        }
        #intro-container {
            #programming {
                margin-top: 30vh;
            }
        }
    }
}

@media (min-width: 478px) {
    .splash-container {
        #intro-container {
            #programming {
                margin-top: 20vh;
            }
        }
    }
}

@media (min-width: 552px) {
    .splash-container {
        #intro-container {
            #programming {
                margin-top: 10vh;
            }
        }
    }
}

@media (min-width: 616px) {
    .splash-container {
        #intro-container {
            #programming {
                margin-top: 0vh;
            }
        }
    }
}

@media (min-width: 768px) {
    .splash-container {
        #intro-container {
            #intro {
                margin-left: 5%;
            }
        }
    }
}

@media (min-width: 1024px) {
    .splash-container {
        #intro-container {
            #programming{
                margin-top: -30vh;
                width: 70%;
            }
            #intro {
                font-size: 125%;
            }
        }
    }
}

@media (min-width: 1440px) {
    .splash-container {
        #intro-container {
            #intro {
                font-size: 200%;
            }
        }
    }
}

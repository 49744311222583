@import "../ComponentStyles/base";

.nav-container {
    display: flex;
    justify-content: center;
    z-index: 100;
    top: 0;
    width: 100vw;
    margin-top: 0px;
    background-color: whitesmoke;
    position: sticky;
    top: 0px;

    .logo {
        background-image: url(./../assets/TP-logo2.svg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 50px;
        width: 50px;
        margin: 10px;
    }

    .navigation {
        margin: 10px 10px 5px 10px;
        display: flex;
        flex-direction: row;
        width: 75vw;
        height: 100%;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .nav-item {
            // height: 25px;
            font-size: 1em;
            padding: 3px;
            a{
                margin: 0;
            }
        }
    }
}

@media(min-width: 1024px){
    .nav-container{
        .navigation{
            .nav-item{
                font-size: 1.5em;
            }
        }
    }
}

@media(min-width: 1440px){
    .nav-container{
        .navigation{
            .nav-item{
                font-size: 2em;
            }
        }
    }
}
@import "_base";
@import "../Components/Nav/nav";
@import "../Components/Landing/landing";
@import "../Components/SplashPage/splashpage";
@import "../Components/Footer/footer";
@import "../Components/Contact/contact";
@import "../Components/ContactForm/contactform";

*{
    text-decoration: none;
    margin: 0; 
    padding: 0;
    box-sizing: border-box;
    border: none;
    outline: none;
    list-style: none;
    font-family: "Roboto", sans-serif !important;
}
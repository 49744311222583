@import url("https://fonts.googleapis.com/css?family=Lato|Montserrat|Poppins|Roboto");
// $laptop: 1024px;
// $tablet: 768px;
// $mobileL: 425px;
// $mobileS: 320px;

// @mixin laptop {
//     @media (max-width: $laptop) and (min-width: $tablet) {
//         @content
//     }
// }

// ;

// @mixin tablet {
//     @media (max-width: $tablet) and (min-width: $mobileL) {
//         @content
//     }
// }

// ;

// @mixin mobile {
//     @media (max-width: $mobileL) and (min-width: $mobileS) {
//         @content
//     }
// }

@mixin splash-dim {
    height: 100vh;
    width: 100vw;
}

@mixin icon-set {
    height: 100px;
    width: 100px;
    padding: 3px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 10px;

    @media(min-width:1024px){
        height: 150px;
        width: 150px;
    }
}

@mixin footer-icon{
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 100;
    color: white;

    @media(min-width: 1024px){
        height: 75px;
        width: 75px;
    }
}

//Projects

@mixin project-set {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 340px;
    width: 90vw;
    border: 2px solid rgba(215,215,215,1);
    padding: 5px;
    margin-bottom: 15px;
    background-color: whitesmoke;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    &:hover{
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }

    @media(min-width: 768px){
        height: 62vh;
        width: 40vw;
        margin: 20px;
    }
    @media(min-width: 1024px){
        height: 440px;
        width: 29vw;
        margin: 15px;

        h3{
            font-size:1.5em;
        }
        p{
            font-size: 1em;
        }
    }
    @media(min-width: 1440px){
        height: 400px;
    }

    .tech{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-self: flex-start;
        li{
            padding-right: 5px;
        }
    }
    
    .links{
        align-self: flex-start;
    }

    h4{
        align-self: flex-start;
    }
    hr{
        width: 80%;
        border-top: 1px solid grey;
        margin-bottom: 5px;
    }
}

//Color generator
$s-min: 20;
$s-max: 70;
$l-min: 30;
$l-max: 90;

$random-color: hsl(
    random(360),
    $s-min + random($s-max + -$s-min),
    $l-min + random($l-max + -$l-min)
);

@mixin linearGradient($color, $type){
    $to: (
        light: lighten($color,15%),
        lightest: lighten($color, 30%),
        dark: darken($color,15%),
        darkest: darken($color,30%)
    );

    font-family: $type;
    color: $color;
    background: nth(nth($color, 1), 1);
    background: webkit-linear-gradient(legacy-direction($color), map-get($to,$type));
    background: linear-gradient($color,map-get($to,$type));
}

@mixin projectPic {
    width: 175px;
    height: 100px;
    margin-left: 5px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @media(min-width: 425px){
        width: 200px;
        height: 125px;
    }
    @media(min-width: 768px){
        width: 225px;
        height: 125px;
    }
    @media(min-width: 1024px){
        width: 250px;
        height: 150px;
    }
}

//Misc

$purple: #321142;
$red: #F2413E;
$bg-cover: cover;
$bg-center: center;
$bg-no-repeat: no-repeat;
$roboto: "Roboto";

//Transition for Contact Page

.contact-enter {
    transform: translate(100%);
  }
  .contact-enter.contact-enter-active {
    transform: translate(0%);
    transition: transform 1000ms ease-in-out;
  }
  .contact-leave {
    transform: translate(0%);
  }
  .contact-leave.contact-leave-active {
    transform: translate(-100%);
    transition: transform 1000ms ease-in-out;
  }
@import "../ComponentStyles/base";

.footer-container {
    height: 20%;
    width: 100vw;
    background-color: $purple;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 75vw;
        margin-top: 5%;
        height: 100%;

        .git {
            @include footer-icon;
            border-radius: 50%;
            border: 2px solid white;
            padding: 10px;

            svg {
                fill: white;
            }
        }
        .git:hover {
            border: $purple;
            background-color: white;
            svg {
                fill: $purple;
            }
        }

        .linkedIn {
            @include footer-icon;
            border-radius: 50%;
            border: 2px solid white;
            padding: 10px;

            svg {
                fill: white;
            }
        }
        .linkedIn:hover {
            border: $purple;
            background-color: white;
            svg {
                fill: $purple;
            }
        }

        .instagram {
            @include footer-icon;
            border-radius: 50%;
            border: 2px solid white;
            padding: 10px;

            svg {
                fill: white;
            }
        }
        .instagram:hover {
            border: $purple;
            background-color: white;
            svg {
                fill: $purple;
            }
        }
    }
    .copyright {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 50%;
        margin-top: 20px;

        p {
            color: white;
            font-size: 1em;
            font-weight: bolder;
            margin-bottom: 15px;
        }
    }
}

@media (min-width: 1024px) {
    .footer-container {
        .copyright {
            p {
                margin-top: 20px;
                // font-size: 1.5em;
            }
        }
    }
}
